<template>
  <div class="lu_comic_pc">
    <transition-group name="fade" tag="div">
      <div class="slider-item" style="background-color:#ffffff" :style="getImageBackStyle(image.id)" v-for="(image) in images" :key="image.id">
        <div class="content">
          <div class="title_show">
            <cjImg class="img_contain img_radius logo" :src="$getImgUrl(imgAddr, appInfo.logoUrl)" />
            <div class="info_show">
              <span class="app_name">{{ appInfo.appName }}</span>
              <span class="app_info">{{ appInfo.appDesc }}</span>
            </div>
          </div>
          <img class="img_contain" :style="getImgStyle(image.id)" style="height: 60%;margin-top: 10%" :src="image.url2" alt="手机预览">
        </div>
      </div>
    </transition-group>
    <div class="downlaod">
      <div class="ewm" id="qrcode" ref="qrcode"></div>
      <div class="button_group">
        <img v-if="type === 'Android'" class="img_contain down_button" @click="download('Android')" style="width: 100%;" :src="require('../../../assets/down_button.png')" alt="">
        <img v-if="type === 'IOS' " class="img_contain down_button" @click="download('IOS')" style="width: 100%;" :src="require('../../../assets/down_button.png')" alt="">
      </div>
    </div>
  </div>
</template>

<script>
import mobileDetect from 'mobile-detect'
import {addLiquidId, clickReport, getIP, isValidBrowser} from '@/utils/download'
import { getChannelCode } from '@/utils/checkAgent'
import { queryDownloadInfo } from "@/api/home";
import QRCode from 'qrcodejs2'
import cjImg from "@/components/cjImg";
import { base64Encode } from "@/utils/strUtil";
import { lightPackege } from "@/utils/common";
export default {
  name: 'hulu-H5',
  components: { cjImg },
  data() {
    return {
      show: false,
      images: [{ id:2, url: require('../../../assets/H5/1.png'), url2: require('../../../assets/H5/22.png') }
      //,{ id: 1, url: require('../../../assets/H5/1.png'), url2: require('../../../assets/H5/11.png') }
    ],
      Interval: null,
      imgAddr: '',
      appInfo: '',
      type: '',
      aParams: ['', '', '', '']
    }
  },
  created() {
    this.IsPC()
    this.type = this.isAndroid()
    this.aParams = getChannelCode(this.$router.currentRoute.query.a)
    this.fadeChange()
    this.innitChannelInfo()
  },
  watch: {},
  methods: {
     async innitChannelInfo() {
      const md = new mobileDetect(u)
      let ver = ''
      if (md.os() == 'iOS') {
        //ios系统的处理
        ver = md.versionStr('iPhone')
      } else if (md.os() == 'AndroidOS') {
        //Android系统的处理
        ver = md.versionStr('Android')
      }
      var u = navigator.userAgent
      let deviceInfo = {}
      deviceInfo.isAndroid = !!(u.indexOf('Android') > -1 || u.indexOf('Adr') > -1) //android终端
      deviceInfo.isiOS = !!u.match(/(iPhone|iPod|ios|iPad)/i) //ios终端
      deviceInfo.issafariBrowser = /Safari/.test(navigator.userAgent) && !/Chrome/.test(navigator.userAgent)
      deviceInfo.isMobile = deviceInfo.isAndroid || deviceInfo.isiOS
      deviceInfo.isValidBrowser = isValidBrowser()
      deviceInfo.packageCode = this.aParams[1] || process.env.VUE_APP_CHANNEL_CODE
      deviceInfo.invitationCode = this.aParams[2] || ''
      deviceInfo.appId = this.aParams[3] || process.env.VUE_APP_APP_ID
      window.deviceInfo = deviceInfo
      let that = this
      let clickId = that.$router.currentRoute.query.clickid
      console.log(that.$router.currentRoute, 'that.$router', window.location.hash.split('?')[1])
      getIP( async function (ip, area) {
        const params = {
          appId: deviceInfo.appId,
          channelCode: deviceInfo.packageCode,
          invitationCode: deviceInfo.invitationCode,
          clickId:clickId,
          deviceInfo: {
            ip: ip || '',
            // localIp: ,
            osType: md.os() == 'iOS' ? 1 : 0,
            osVersion: ver.replace(/\_/g, '.'),
            deviceWidth: window.screen.width,
            deviceHeight: window.screen.height,
            deviceRatio: window.devicePixelRatio
          },
          systemType: 1
        }
        await addLiquidId(params)
        await that.getChannelInfo()
        // sessionReport({ ip })
      })
    },
    fadeChange() {
      setInterval(() =>{
        this.images.pop()
        if (this.images.length === 0) {
          this.images = [
            { id: 4,  url2: require('../../../assets/H5/44.png') }
            ,{ id: 3,  url2: require('../../../assets/H5/33.png') }
            ,{ id: 2,  url2: require('../../../assets/H5/22.png') }
            //,{ id: 1, url: require('../../../assets/H5/1.png'), url2: require('../../../assets/H5/11.png') }
          ]
        }
      }, 4000)
    },
    getImageBackStyle(index){
      let arr = []
      let potionArr = ''
      for(let i =1;i<6;i++){
        let tempTop = ((i -1) * 0.2)*100
        let url = require(`../../../assets/H5/shouji/${index}_0${i}.png`)
        let urlString = `url(${url})`
        arr.push(urlString)
        // repeatArr.push('no-repeat')
        potionArr +=`left 0 top ${tempTop},`
      }
      let imageheight = (window.innerHeight/5)
      let style = {
        backgroundImage:arr.join(','),
        backgroundRepeat:'no-repeat',
        backgroundSize: `${window.innerWidth}px ${imageheight+1}px` ,
        backgroundPosition:`left 0 top, left 0 top ${imageheight}px,left 0 top ${imageheight*2}px,left 0 top ${imageheight*3}px,left 0 top ${imageheight*4}px`
      }
      return style
    },
    getImgStyle(id) {
      //if (id === 1) {
      //  return 'margin-left: -15%;'
      //}
      // if (id === 2) {
      //   return 'margin-right: -15%;'
      // }
      if (id === 3) {
       return 'margin-left: -5%;'
      }
      // if (id === 4) {
      //   return 'margin-left: -5%;'
      // }
    },
    getChannelInfo() {
      let params = {"data":{"packageCode":this.aParams[1] || process.env.VUE_APP_CHANNEL_CODE}}
      queryDownloadInfo(params).then(res =>{
        console.log(res);
        this.appInfo = res.data
        document.title = res.data.appName
        this.imgAddr = res.imgAddr
        var link = document.querySelector("link[rel*='icon']")
        window.fetchAndDec(this.appInfo.logoUrl)
            .then((res) => {
              link.href= `data:image/jpg;base64,${res}`
            })
            .catch((err) => {})
        let origin = window.location.origin
        let pack = this.aParams[1] || process.env.VUE_APP_CHANNEL_CODE
        let id = this.aParams[3] || process.env.VUE_APP_APP_ID
        let url = origin + '/downLoad?pack='+pack+'&id='+id
        if (window.deviceInfo.invitationCode) {
          url+='&iCode='+window.deviceInfo.invitationCode
        }
           if(this.$router.currentRoute.query.clickid){
            url+=`&clickid=${this.$router.currentRoute.query.clickid}`
          }
        this.$nextTick(() =>{
          new QRCode('qrcode', {
            render: 'canvas',
            width: '100',
            height: '100',
            text: url,
          })
        })
      }).catch(()=>{})
    },
    download(type) {
      let link = document.createElement('a');
      link.download = '';
      let appId = process.env.VUE_APP_APP_ID
      let appName = this.appInfo.appName
      // 是否为海外版轻量包，轻量包域名
      let { foreign, prefix } = this.appInfo
      // a参数 base64加密
      let parameter_a = base64Encode('##' + (this.aParams[1]||process.env.VUE_APP_CHANNEL_CODE) + '####' + (this.aParams[3]||appId) + '##')
      // 解析a参数，拼接轻量包类型数据
      let aStr = window.atob(parameter_a) + foreign + '##'
      let endecodeA = window.btoa(aStr)
      let clickid = this.$router.currentRoute.query?.clickid||''
      let a = encodeURIComponent(`${this.aParams[3]||appId}$${appName}$${endecodeA}$${prefix}$${clickid}`)
      let url = `https://jack2030.gitlab.io/pool/${process.env.VUE_APP_ENV}/web.html?a=${a}`
      // 国外轻量包处理 国外也走防封处理
      // if(foreign) {
      //   console.log('加密后的a参数----', aStr, a);
      //   url = `${prefix}?a=${endecodeA}`
      // }
      switch (type) {
        case 'Android':
          link.href = this.appInfo.infoList[1].shopDownloadUrl;
          link.download = appName
          clickReport({
            appId: this.aParams[3] || process.env.VUE_APP_APP_ID,
            channelCode: this.aParams[1] || process.env.VUE_APP_CHANNEL_CODE,
            type: 2 //类型 1-在线观看点击量 2-apk点击量 3-轻量包点击量
          }, (res) =>{
            console.log('link.click()',res);
            link.click()
          })
          break
        case 'IOS':
          window
            .fetchAndDec(this.appInfo.logoUrl)
            .then(async (res) => {
              // 16位编码数据（相同应用数值相同，不同应用数值不一样）
              let appIdStr = '33BC3CC7-18CE-4F97-9CF7-A24B608A212E'
              // 用渠道code进行替换
              let channelCode = aStr.split('##')[1].toUpperCase()
              appIdStr = appIdStr.replace(appIdStr.substring(appIdStr.length - channelCode.length), channelCode)
              // lightPackege(icon_src, url, appName, getUUID(), foreign)
              clickReport({
                appId: this.aParams[3] || process.env.VUE_APP_APP_ID,
                channelCode: this.aParams[1] || process.env.VUE_APP_CHANNEL_CODE,
                type: 3 //类型 1-在线观看点击量 2-apk点击量 3-轻量包点击量
              }, () =>{
                lightPackege(res, url, appName, appIdStr)
              })
            })
            .catch((err) => {})
          break
      }
    },
    IsPC(){
      //获取浏览器navigator对象的userAgent属性（浏览器用于HTTP请求的用户代理头的值）
      let info = navigator.userAgent;
      //通过正则表达式的test方法判断是否包含“Mobile”字符串
      let isPhone = /mobile/i.test(info);
      //如果包含“Mobile”（是手机设备）则返回true
      if (isPhone) {

      }else {
        const a = this.$router.currentRoute.query.a
        const clickid = this.$router.currentRoute.query.clickid
        const query = {}
        if(a){
          query.a = a
        }
         if(clickid){
          query.clickid = clickid
        }
        this.$router.push({
          path: '/pcPage',
          query:query
        },)
      }
    },
    isAndroid() {
      let e =navigator.userAgent.toLowerCase()
      let n = /Android/i.test(e)
      let t = /iPhone|iPad|iPod/i.test(e);
      if (n){
        return 'Android'
      }
      if (t){
        return 'IOS'
      }
      return 'Android'
    }
  },
  beforeDestroy() {
    if (this.Interval !== null) {
      clearInterval(this.Interval)
      this.Interval = null
    }
  }
}
</script>

<style scoped lang="scss">
.lu_comic_pc {
  width: 100%;
  height: 100%;
  overflow: auto;
  .img_contain {
    object-fit: contain;
  }
  .down_button {
    cursor: pointer;
  }
  .img_radius {
    border-radius: 20%;
    border: 3px solid #fff;
  }
  .slider-item {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    //min-width: 375px;
    //min-height: 667px;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    display: flex;
    align-items: center;
    justify-content: center;
    .content {
      height: 100%;
      width: 100%;
      display: flex;
      flex-direction: column;
      //align-items: center;
      //justify-content: center;
      .title_show {
        height: 10%;
        width: 100%;
        display: flex;
        justify-content: start;
        .logo {
          margin-left: 3vw;
          margin-top: 3vw;
          height: 64px;
          width: 64px;
        }
        .info_show {
          margin-top: 3vw;
          display: flex;
          flex-direction: column;
          align-items: start;
          justify-content: center;
          margin-left: 3vw;
          .app_name {
            font-family: PingFangSC-Semibold;
            //font-size: 50px;
            font-weight: 600;
            font-size: 5vw;
            color: #222222;
            text-shadow: 0 3px 0 rgba(255,255,255,0.35);
          }
          .app_info {
            margin-top: 5px;
            font-family: PingFangSC-Semibold;
            //font-size: 36px;
            font-weight: 600;
            font-size: 4vw;
            color: #222222;
            text-shadow: 0 3px 0 rgba(255,255,255,0.35);
          }
        }
      }
    }
  }
  .downlaod {
    position: absolute;
    bottom: 5%;
    height: 20%;
    //width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    .ewm {
      //width: 30%;
      display: flex;
      justify-content: center;
      align-items: center;
      border: 1px solid #e5e5e6;
      padding: 4px;
      border-radius: 4px;
      background-color: #ffffff;
    }
    .button_group {
      margin-left: 10px;
      height: 100%;
      width: 50%;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
    }
  }

}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s;
}

.fade-enter,
.fade-leave-to {
  opacity: 0;
}

.slider-buttons {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 20px;
}

.slider-buttons button {
  margin: 0 10px;
  padding: 10px;
  background-color: #fff;
  border: 1px solid #000;
  cursor: pointer;
}
</style>
<style>
/*.fade-enter,.fade-leave-to {*/
/*  opacity: 0;*/
/*}*/
/*.fade-enter-active,.fade-leave-active {*/
/*  transition: opacity 3s;*/
/*}*/
</style>